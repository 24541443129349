//plugins
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';
import 'letteringjs';
import 'textillate';
import 'leaflet/dist/leaflet';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use  for styles
// custom
import '../styles/index.scss';

import { main } from "./main";
import { map } from "./map";

$(document).ready(function() {
  new main();
  new map();
  $('.tlt').textillate();
  AOS.init();
});

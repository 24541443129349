import bulmaCarousel from 'bulma-carousel/dist/js/bulma-carousel.min.js';

export class main {
  constructor(){
    this.navBarScroll();
    this.burgerClick();
    this.scrollSection();
    this.initCarousel();
  }

  initCarousel(){
    $('.main-carousel').each((i, el) => {
      bulmaCarousel.attach(el, {
        slidesToScroll: 1,
        autoplay: true,
        loop: true,
        pauseOnHover: false,
        navigationKeys: false,
        navigation: false,
        slidesToShow: 1
      });
    });
  }

  scrollSection(){
    $(window).scroll(()=> {

      $('section').each(function(i) {
        var scrollDistance = $(window).scrollTop() + 120;
        if ($(this).position().top <= scrollDistance) {
          $(".navbar a.active").removeClass('active');
          $(".navbar a").eq(i + 1).addClass('active');
        }
      });
    });
  }

  burgerClick(){
    $(".navbar-burger").click(function() {
      $(".navbar-burger").toggleClass("is-active");
      $(".navbar-menu").toggleClass("is-active");
    });
  }

  navBarScroll(){
    $(".scroll-link").on('click', function(event) {
      if (this.hash !== "") {
        event.preventDefault();
        const hash = this.hash;
        $('html, body').animate({
          scrollTop: $(hash).offset().top - 100
        }, 800, function(){
          try {
            history.pushState({hash: hash}, '', window.location.origin + '/' + hash);
          } catch(e) {
            window.location.hash = hash;
          }
        });
      }
    });
  }
}

$ = $ || jQuery;

export class map
{
  constructor(){
    this.map = null;

    if($('#page-map').length === 1)
    {
      let LeafIcon = L.Icon.extend({
        options: {
          shadowUrl: '/public/assets/map/marker-shadow.png',
        }
      });

      this.icon = new LeafIcon({
        iconUrl: '/public/assets/map/marker-icon.png',
        iconSize: [25,41],
        iconAnchor: [12,41]
      });

      this.initMap();
    }
  }

  initMap(){
    this.map = L.map('page-map').setView([54.343564,17.894174], 8);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);
    setTimeout(() => {
      this.map.invalidateSize();
      // for(let i in displayMarkers) {
        this.addMarker(54.343531274710955,17.894163000169073,  'ul. Kartuska 14A, 83-340 Sierakowice');
      // }
    }, 100);
  }

  addMarker(lat, lng, description) {
    const coordinates = [lat, lng];
    L.marker(coordinates, {icon: this.icon}).addTo(this.map).bindPopup(description);
  }
}
